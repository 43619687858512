import {
  SortColumn,
  SortDirection,
  type ISearchPropertyLeasePortfolioRequest,
} from '@portfolio'
import type { Ref } from 'vue'
import type { SortingState } from '@tanstack/vue-table'

export const useSortPortfolio = (
  filters: Ref<ISearchPropertyLeasePortfolioRequest>,
) => {
  const sort = computed<SortingState>({
    get: () => {
      if (
        !filters.value.sort ||
        !filters.value.direction ||
        filters.value.sort === SortColumn.PUBLISHED_AT
      ) {
        return []
      }

      const sortKey = filters.value.sort as string
      const isDescending = filters.value.direction === SortDirection.DESCENDING

      return [{ id: sortKey, desc: isDescending }]
    },
    set: (sortingState) => {
      const sortState = sortingState?.[0]
      if (!sortState) {
        filters.value.sort = SortColumn.PUBLISHED_AT
        filters.value.direction = SortDirection.DESCENDING
        return
      }

      const sortDirection = sortState.desc
        ? SortDirection.DESCENDING
        : SortDirection.ASCENDING

      filters.value.sort = sortState.id as SortColumn
      filters.value.direction = sortDirection
    },
  })

  return { sort }
}
