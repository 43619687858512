import {
  useQuery,
  keepPreviousData,
  useInfiniteQuery,
} from '@tanstack/vue-query'
import { GroupByOption, SortColumn, SortDirection } from '@portfolio'
import type {
  ISearchPropertyLeasePortfolioRequest,
  ISearchPropertyLeasePortfolioResponse,
} from '@portfolio'
import type { MaybeRef } from 'vue'

const DEFAULT_FILTERS = () => ({
  groupBy: GroupByOption.NONE,
  sort: SortColumn.PUBLISHED_AT,
  direction: SortDirection.DESCENDING,
})

export const fetchPortfolio = (
  companyId: string,
  query: { page: number; pageSize: number },
  body: ISearchPropertyLeasePortfolioRequest = {},
  signal?: AbortSignal,
) =>
  fetchAPI<ISearchPropertyLeasePortfolioResponse>(
    `/companies/${companyId}/portfolio/property/search`,
    {
      method: 'POST',
      query,
      body,
      signal,
    },
  )

export const useApiPortfolio = (
  companyId: string,
  query: MaybeRef<{ page: number; pageSize: number }>,
  body: MaybeRef<ISearchPropertyLeasePortfolioRequest> = {},
) => {
  return useQuery({
    queryKey: ['useApiPortfolio', companyId, body, query],
    queryFn: ({ signal }) =>
      fetchPortfolio(companyId, toValue(query), toValue(body), signal),
    placeholderData: keepPreviousData,
    gcTime: 60 * 60 * 24 * 7,
    staleTime: 0,
  })
}

export const useApiPortfolioInfinite = (
  companyId: string,
  body: MaybeRef<ISearchPropertyLeasePortfolioRequest> = {},
  pageSize: number = 10,
) => {
  return useInfiniteQuery({
    queryKey: ['useApiPortfolio', companyId, 'infinite', body],
    queryFn: ({ pageParam, signal }) =>
      fetchPortfolio(
        companyId,
        { page: pageParam, pageSize },
        toValue(body),
        signal,
      ),
    getNextPageParam: (lastPage) => {
      const page = lastPage.result?.page?.page ?? 1
      const totalPages = lastPage.result?.page?.totalPages ?? 1
      return page < totalPages ? page + 1 : undefined
    },
    initialPageParam: 1,
  })
}

export const usePortfolio = (
  companyId: string,
  initialFilters: Partial<ISearchPropertyLeasePortfolioRequest> = {},
) => {
  const filters = useFilters<ISearchPropertyLeasePortfolioRequest>(() => ({
    ...DEFAULT_FILTERS(),
    ...initialFilters,
  }))

  const parsedFilters = computed(
    () => parseFilters(filters.value) as ISearchPropertyLeasePortfolioRequest,
  )
  const { count: numberOfFiltersApplied } = usePortfolioFilterCount(filters)

  const hasFiltersApplied = computed(() => {
    return numberOfFiltersApplied.value > 0
  })

  const clearFilters = () => {
    filters.value = DEFAULT_FILTERS()
  }
  const filtersObserver = computed(() => JSON.stringify(parsedFilters.value))
  const { sort } = useSortPortfolio(filters)

  const { pagination, changePage } = usePagination({ persist: true })

  // Go back to page 1 on change filter
  watch(filtersObserver, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      changePage(1)
      checkFilterChanges(filters.value)
    }
  })

  const query = useApiPortfolio(companyId, pagination, parsedFilters)

  return {
    ...query,
    filters,
    pagination,
    sort,
    hasFiltersApplied,
    numberOfFiltersApplied,
    clearFilters,
    changePage,
  }

  function checkFilterChanges(
    curFilters: ISearchPropertyLeasePortfolioRequest,
  ): boolean {
    const filterKeysToCheck: (keyof ISearchPropertyLeasePortfolioRequest)[] = [
      'searchTerm',
      'searchLeaseName',
      'searchLessee',
      'searchLessor',
      'searchPropertyName',
      'searchPropertyAddress',
      'searchTradingName',
      'statuses',
      'lessees',
      'lessors',
      'sectors',
      'sizeRange',
      'countries',
      'states',
      'cities',
      'tradingNames',
      'commencementDateRange',
      'expiryDateRange',
      'baseRentPerSqmRange',
      'missingAllOf',
      'missingAnyOf',
    ]

    return filterKeysToCheck.some((key) => {
      const value = curFilters[key]

      if (Array.isArray(value)) {
        return value.length > 0
      } else if (typeof value === 'object' && value !== null) {
        return Object.values(value).some((v) => !!v)
      } else if (typeof value === 'string') {
        return !!value.trim().length
      } else {
        return !!value
      }
    })
  }
}
